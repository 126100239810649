import logo from '@/assets/logo.png'
import { mapActions, mapGetters } from 'vuex'
import dateUtils from '@/utils/dateUtils.js'
import config from '@/../package.json'
import ResetPasswordModal from '@/components/ResetPasswordModal/ResetPasswordModal.vue'

export default {
    name: "login",
    data() {
        return {
            version: config.version,
            logo: logo,
            login_form: {
                username: undefined,
                password: undefined
            },
            login_form_rule: {
                username: [{ required: true, message: '请输入手机号码', trigger: 'blur' },{ validator: this.$validateUtils.validateMobilePhone }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            login_loading: false,

            resetPasswordModal: false
        }
    },
    components: {
        ResetPasswordModal
    },
    computed: {
        ...mapGetters([
            'mobilephone'
        ]),
    },
    methods: {
        ...mapActions(['Login', 'GetInfo', 'GetStaffInfo', 'Logout']),
        login() {
            const that = this
            // 登录之前先登出一下，以免bug
            that.Logout()
            that.$refs['login_form'].validate((valid, fields) =>{
                if (valid) {
                    that.login_loading = true
                    that.Login({...that.login_form}).then(res => {
                        if(res.errcode === that.$consts.biz.OK) {
                            that.GetInfo().then(res1 => {
                                if(res1.errcode === that.$consts.biz.OK) {
                                    that.GetStaffInfo().then(res2 => {
                                        that.login_loading = false
                                        if(res2.errcode === that.$consts.biz.OK) {
                                            localStorage.setItem(that.$consts.storageKey.LAST_LAUNCH_TIME_.concat(that.mobilephone), dateUtils.getNow())
                                            that.$router.push({ name: 'Student' })
                                        }
                                    }).catch(e => {
                                        that.login_loading = false
                                    })
                                }else {
                                    that.login_loading = false
                                }
                            }).catch(e1 => {
                                that.login_loading = false
                            })
                        }else {
                            that.login_loading = false
                        }
                    }).catch(e => {
                        that.login_loading = false
                    })
                }
            })
        }
    }
}

<template>
  <a-modal v-model="thisVisible" title="设置新密码" @ok="handleOk()" @cancel="onClose()" :width="400" centered>
    <a-form-model ref="resetForm" layout="horizontal" labelAlign="left" :model="resetForm"
                  :rules="resetFormRule" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
      <a-form-model-item label="手机号码" prop="mobilePhone">
        <a-input v-model="resetForm.mobilePhone" />
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="verificationCode">
        <a-input v-model="resetForm.verificationCode">
          <span v-if="second == 0" slot="suffix" class="huiwen-pointer huiwen-text-blue" @click="sendVerificationCode">验证码</span>
          <span v-else slot="suffix">{{second}}</span>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="password">
        <a-input v-model="resetForm.password" type="password"/>
      </a-form-model-item>
      <a-form-model-item label="确认新密码" prop="password2">
        <a-input v-model="resetForm.password2" type="password"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

  import { sendSmsCode } from '@/api/core'
  import { resetPassword } from '@/api/login'
  import md5 from 'md5'

  export default {
    name: 'ResetPasswordModal',
    props: {
      visible:Boolean
    },
    watch: {
      'visible': function(newVal,oldVal) {
        if(newVal && !oldVal) {
          this.thisVisible = true
        }
        if(!newVal && oldVal) {
          this.thisVisible = false
        }
      }
    },
    data() {
      return {
        thisVisible: false,

        resetForm: {
          mobilePhone: undefined,
          verificationCode: undefined,
          password: undefined,
          password2: undefined
        },
        resetFormRule: {
          mobilePhone: [{ required: true, message: '请输入手机号码', trigger: 'blur' },{ validator: this.$validateUtils.validateMobilePhone }],
          verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          password: [{ required: true, message: '请输入新密码', trigger: 'blur' },{ validator: this.$validateUtils.validatePassword }],
          password2: [{ required: true, message: '请输入新密码', trigger: 'blur' },{
            validator: (rule, value, callback) => {
              if(value != this.resetForm.password) {
                callback(new Error('两次输入不一致'))
              }
              callback()
            },
            trigger: 'change'
          }]
        },
        second: 0
      }
    },
    methods: {
      initResetForm() {
        const that = this
        that.resetForm = {
          mobilePhone: undefined,
          verificationCode: undefined,
          password: undefined,
          password2: undefined
        }
        that.$refs['resetForm'].resetFields()
      },
      handleOk() {
        const that = this
        that.$refs['resetForm'].validate((valid, fields) => {
          if (valid) {
            var { mobilePhone, verificationCode, password } = that.resetForm
            resetPassword({
              mobilePhone,
              verificationCode,
              password: md5(password)
            }).then(res => {
              if(res.errcode >= that.$consts.biz.OK) {
                that.$message.success(that.$consts.biz.OK_MSG)
                that.onClose()
              }else {
                that.$message.error(res.errmsg)
              }
            })
          }
        })
      },
      onClose() {
        const that = this
        that.initResetForm()
        that.$emit('close')
      },
      sendVerificationCode() {
        const that = this
        var phoneNumber = that.resetForm.mobilePhone
        var reg = /^1[3456789]\d{9}$/
        if(!reg.test(phoneNumber)){
          that.$message.error('请先输入正确的手机号码')
          return
        }

        sendSmsCode(phoneNumber).then(res => {
          if(res.errcode >= that.$consts.biz.OK) {
            that.second = 60
            var job = setInterval(() => {
              that.second--
              if(that.second == 0) {
                clearInterval(job)
              }
            },1000)
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>